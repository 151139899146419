'use client';

import { useTranslations } from 'next-intl';
import { LoadingWrapper, StyledSpinner } from 'src/components/common';

export default function Loading() {
  const t = useTranslations();
  return (
    <LoadingWrapper>
      <StyledSpinner />
      <p>{t('common.loading')}</p>
    </LoadingWrapper>
  );
}
